import React from "react";
import { graphql } from "gatsby";
import { Box, Container } from "@chakra-ui/react";
import Layout from "../layouts/Layout";

export const PrivacyPolicyPageQuery = graphql`
  query {
    heroBg: file(relativePath: { eq: "hero-bg-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
      extension
      publicURL
    }
  }
`;

function PrivacyPolicyPage({ data }) {
  return (
    <Layout pageTitle="Privacy Policy" backgroundImage={data.heroBg}>
      <Box padding="80px 30px">
        <Container maxW="container.md">
          <div className="content">
            <h2>MOVIELABS LEGAL AND PRIVACY NOTICES</h2>
            <p>
              MovieLabs respects your privacy and is committed to protect the
              personal information that you share with us. Generally, you can
              browse through our website without giving us any information about
              yourself. When we do need your personal information to work with
              you or when you choose to provide us with your personal
              information, this policy describes how we collect and use your
              personal information.
            </p>

            <h3>Information Collection</h3>
            <p>
              Personal information means any information that may be used to
              identify an individual, including, but not limited to, a first and
              last name, email address, a company or home, postal or other
              physical address, other contact information, title, birth date,
              gender, occupation, industry, personal or product interests, other
              information when needed to provide a service you requested.
            </p>

            <p>
              When you browse our website, you do so anonymously. We don’t
              collect personal information, including your email address, unless
              you indicate that you wish to work with MovieLabs or choose to
              provide us with your personal information. We do log your IP
              address (the Internet address of your computer) to give us an idea
              of which part of our website you visit and how long you spend
              there. But we do not link your IP address to any personal
              information unless you log in to a portion of our website that
              requires a login and password. Like many other commercial
              websites, the MovieLabs website may use a standard technology
              called a “cookie” to collect information about how you use the
              site. Please go to “Cookies and Tracking Information” below for
              more information.
            </p>

            <p>
              MovieLabs collects personal information when you register with
              MovieLabs, when you submit a proposal to MovieLabs, when you
              register to attend a seminar or participate in an online survey,
              when you ask to be included in an email or other mailing list, or
              when you submit your information to MovieLabs for any other
              reason.
            </p>

            <p>
              Access to certain MovieLabs web pages may require a login and a
              password. The use of those web pages, and the information or
              programs downloadable from those sites, may be governed by a
              written agreement between you or your employer and MovieLabs. Your
              personal information may be retained by MovieLabs to verify
              compliance with the agreement and log or track usage of those
              sites.
            </p>

            <h3>Notice</h3>
            <p>
              If you choose to provide us with your personal information, we may
              transfer that information, within MovieLabs or to MovieLabs’s
              third party service providers, sponsoring studios, technology
              consultants, or other advisors, including across borders and from
              your country or jurisdiction to other countries or jurisdictions
              around the world.
            </p>

            <p>
              When personal information is collected, we will inform you at the
              point of collection of the purpose for the collection. If we
              engage in marketing efforts with you, we will always give you the
              opportunity to “opt out” of receiving direct marketing or market
              research information. This means we assume you have given us your
              consent to collect and use your information in accordance with
              this Policy unless you take affirmative action to indicate that
              you do not consent, for instance by clicking or checking the
              appropriate option or box at the point of collection. In some
              cases, when applicable, we will provide you with the opportunity
              to “opt in.” This means we will require your affirmative action to
              indicate your consent before we use your information for purposes
              other than the purpose for which it was submitted.
            </p>

            <h3>Cookies and Tracking Technology</h3>
            <p>
              A cookie is a small data file that certain Web sites write to your
              hard drive when you visit them. A cookie file can contain
              information such as a user ID that the site uses to track the
              pages you’ve visited, but the only personal information a cookie
              can contain is information you supply yourself. A cookie can’t
              read data off your hard disk or read cookie files created by other
              sites. Some parts of MovieLabs’ website may use cookies to track
              user traffic patterns. We do this in order to determine the
              usefulness of our website information to our users and to see how
              effective our navigational structure is in helping users reach
              that information.
            </p>

            <p>
              If you prefer not to receive cookies while browsing our website,
              you can set your browser to warn you before accepting cookies and
              refuse the cookie when your browser alerts you to its presence.
              You can also refuse all cookies by turning them off in your
              browser, although you may not be able to take full advantage of
              MovieLabs’ website if you do so. You do not need to have cookies
              turned on to use/navigate through many parts of our website,
              except access to certain of MovieLabs’s web pages may require a
              login and password.
            </p>

            <h3>How We Use Information Collected</h3>
            <p>
              MovieLabs uses information for several general purposes: to
              fulfill your requests to work with us on your proposals, to
              personalize your experience on our website, to keep you up to date
              on the latest information from MovieLabs or other information we
              think you’d like to hear about either from us or from our business
              partners, and to better understand your needs and improve our
              website and operations. We may also use your information to send
              you, or to have our business partners send you, direct marketing
              information or contact you for market research.
            </p>

            <h3>Information Sharing and Disclosure</h3>
            <p>
              Your personal information may be shared outside MovieLabs under
              conditions described in this policy. Your information may be
              stored and processed in the United States or any other country
              where MovieLabs, its affiliates or agents are located.
            </p>

            <p>
              MovieLabs may send your personal information to other companies or
              people under any of the following circumstances: when required to
              review proposals or information provided by you with our
              sponsoring studios, technology consultants, or other advisors;
              when we have your consent to share the information; when we need
              to share your information to provide you with information or
              services you have requested; or we want to keep you up to date on
              the latest announcements or other information we think you’d like
              to hear about either from us or from our business partners. We
              will also disclose your personal information if required to do so
              by law, to enforce our Terms of Use, or in urgent circumstances,
              to protect personal safety, the public or our websites.
            </p>

            <h3>Data Security</h3>
            <p>
              If you are participating in any portions of our website that
              require a login, your MovieLabs account information will be
              password-protected for your privacy and security. Inside
              MovieLabs, data is stored in controlled servers with limited
              access. MovieLabs safeguards the security of the data you send us
              with physical, electronic, and managerial procedures. In certain
              areas of our websites, MovieLabs may use industry-standard SSL-
              encryption to enhance the security of data transmissions. While we
              strive to protect your personal information, we cannot ensure the
              security of the information you transmit to us, and so we urge you
              to take every precaution to protect your personal data when you
              are on the Internet. Change your passwords often, use a
              combination of letters and numbers, and make sure you use a secure
              browser.
            </p>

            <h3>Changes to this Privacy Policy</h3>
            <p>
              MovieLabs will amend this policy from time to time. If we make any
              substantial changes in the way we use your personal information we
              will make that information available by posting a notice on this
              site.
            </p>

            <h3>Inquiries or Suggestions</h3>
            <p>
              If you have questions or concerns about our collection, use, or
              disclosure of your personal information, please email us at
              legal@MovieLabs.com.
            </p>

            <h3>European Economic Area Addendum</h3>
            <p>
              If you are located in the European Economic Area (the “EEA”), the
              following EEA-specific provisions apply to our processing of your
              personal information in addition to or in lieu of the relevant
              sections of the Privacy Policy:
            </p>

            <p>
              We rely on the following legal bases under applicable law to
              process your personal information:
            </p>
            <ul>
              <li>If you have consented to such processing;</li>
              <li>
                Where it is necessary for the performance of a contract to which
                you are party or in order to take steps prior to entering into
                such a contract;
              </li>
              <li>
                Where it is necessary to comply with a legal obligation to which
                we are subject; or
              </li>
              <li>
                Where it is necessary for the purposes of our legitimate
                interest, such as to prevent, identify and protect against
                fraud, copyright infringement, unauthorized use and distribution
                of protected content and other criminal activity. We have
                balanced our legitimate interests against your data protection
                rights. You can contact us as described in the “Inquiries or
                Suggestions” section above if you would like more information on
                how we balance our legitimate interests.
              </li>
            </ul>

            <p>
              Under EU data protection law, you have a number of rights with
              respect to the personal information we process about you,
              including:
            </p>
            <ul>
              <li>
                The right to object, at any time, to the processing of your
                personal data which is based on the legitimate interest legal
                basis.
              </li>
              <li>
                Where we process your personal information for direct marketing
                purposes, you have the right to object at any time to such
                processing, including for profiling purposes to the extent that
                it is related to direct marketing.
              </li>
              <li>
                You also have the right to request (i) access to the personal
                information that we process about you, (ii) to have your
                personal information corrected or erased, (iii) to restrict the
                processing of your personal information, and (iv) to receive
                your personal information that you have provided to us in a
                structured, commonly-used and machine readable format, and to
                transmit the data to another controller without hindrance from
                us.
              </li>
              <li>
                Where we have obtained your consent for the processing of your
                personal information, you have the right to withdraw your
                consent at any time. This will not affect the lawfulness of the
                processing that has happened based on your consent prior to the
                withdrawal.
              </li>
            </ul>

            <p>
              To exercise these rights or withdraw consent, please contact us
              using the contact details set out in the “Inquiries or
              Suggestions” section, above.
            </p>

            <p>
              You also have the right to lodge a complaint with a data
              protection supervisory authority.
            </p>

            <p>
              Your personal information will generally be retained for as long
              as is necessary for the purposes set out in this Privacy Policy.
            </p>
          </div>
        </Container>
      </Box>
    </Layout>
  );
}

export default PrivacyPolicyPage;
